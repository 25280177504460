import { setUser as sentrySetUser } from '@sentry/vue'
import type { RubixUser } from '~/types'
import type { JWT } from 'aws-amplify/auth'
import {
  fetchAuthSession as amplifyFetchAuthSession,
  signInWithRedirect as amplifySignInWithRedirect,
  signOut as amplifySignOut
} from 'aws-amplify/auth'

type PayloadWithClaims = JWT['payload'] & {
  'cognito:groups': string[]
  'custom:account_id': string
  'custom:locality': string
  given_name: string
  family_name: string
  email: string
  picture: string
  initials: string
  phoneNumber: string
}

export const login = async () => {
  await amplifySignInWithRedirect({
    provider: {
      custom: 'dpaid-auth0'
    },
    customState: `${window.location.host}/login/auth-callback`
  })
}

export const logout = () => amplifySignOut()

export const getCurrentUser = async (): Promise<RubixUser | undefined> => {
  const payload = await getIdTokenPayload()

  if (payload) {
    const groups = payload['cognito:groups'] || []

    sentrySetUser({
      userId: payload['custom:account_id']
    })

    return {
      accountId: payload['custom:account_id'],
      givenName: payload.given_name,
      familyName: payload.family_name,
      email: payload.email,
      picture: payload.picture,
      initials: payload.initials,
      phoneNumber: payload.phoneNumber,
      isEmployee: groups.includes('dpa:employee'),
      isFreelancer: groups.includes('dpa:freelancer'),
      isAdmin: groups.includes('dpa:administrator'),
      locality: payload['custom:locality'],
      groups: filterUserPoolFromGroup(groups)
    }
  }

  sentrySetUser(null)
}

const filterUserPoolFromGroup = (groups: string[]) => {
  const userPoolGroup = `${import.meta.env.VITE_COGNITO_USER_POOL_ID}_dpaid`
  const userPoolGroupAuth0 = `${
    import.meta.env.VITE_COGNITO_USER_POOL_ID
  }_dpaid-auth0`

  return groups.filter(
    group => group !== userPoolGroup && group !== userPoolGroupAuth0
  )
}

export const getIdTokenPayload =
  async (): Promise<PayloadWithClaims | null> => {
    const idToken = await getIdToken()

    if (idToken === null) return idToken

    return idToken.payload as PayloadWithClaims
  }

// Returns json web token or null
export const getToken = async (): Promise<string | null> => {
  // TODO: it would be nice to only use accessToken and not idToken
  // to prevent exposing unnecessary personal data
  // see https://docs.amplify.aws/react/build-a-backend/auth/concepts/tokens-and-credentials/
  const idToken = await getIdToken()

  if (idToken === null) {
    return idToken
  }

  return idToken.toString()
}

const getIdToken = async (): Promise<JWT | null> => {
  try {
    const { idToken } = (await amplifyFetchAuthSession()).tokens ?? {}

    return idToken || null
  } catch (e: unknown) {
    if (e instanceof Error && e.name === 'NotAuthorizedException') {
      console.warn(e.message)
    } else {
      console.error(e)
    }

    return null
  }
}
