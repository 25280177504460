
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as _91urn_93jjsEUfQ6BQMeta } from "/builds/dpa-gmbh/produktionssysteme/rubix/rubix-monitor-frontend/pages/index/content/[urn].vue?macro=true";
import { default as indexLWYZX9UqOtMeta } from "/builds/dpa-gmbh/produktionssysteme/rubix/rubix-monitor-frontend/pages/index.vue?macro=true";
import { default as auth_45callbackRlqS4MEem3Meta } from "/builds/dpa-gmbh/produktionssysteme/rubix/rubix-monitor-frontend/pages/login/auth-callback.vue?macro=true";
import { default as indexDaftMoxzBvMeta } from "/builds/dpa-gmbh/produktionssysteme/rubix/rubix-monitor-frontend/pages/login/index.vue?macro=true";
import { default as indexkYhgm5C0SHMeta } from "/builds/dpa-gmbh/produktionssysteme/rubix/rubix-monitor-frontend/pages/privacy-policy/index.vue?macro=true";
import { default as indexGWZTWO3PCKMeta } from "/builds/dpa-gmbh/produktionssysteme/rubix/rubix-monitor-frontend/pages/settings/index.vue?macro=true";
export default [
  {
    name: "index",
    path: "/",
    component: () => import("/builds/dpa-gmbh/produktionssysteme/rubix/rubix-monitor-frontend/pages/index.vue"),
    children: [
  {
    name: "index-content-urn",
    path: "content/:urn()",
    component: () => import("/builds/dpa-gmbh/produktionssysteme/rubix/rubix-monitor-frontend/pages/index/content/[urn].vue")
  }
]
  },
  {
    name: "login-auth-callback",
    path: "/login/auth-callback",
    component: () => import("/builds/dpa-gmbh/produktionssysteme/rubix/rubix-monitor-frontend/pages/login/auth-callback.vue")
  },
  {
    name: "login",
    path: "/login",
    component: () => import("/builds/dpa-gmbh/produktionssysteme/rubix/rubix-monitor-frontend/pages/login/index.vue")
  },
  {
    name: "privacy-policy",
    path: "/privacy-policy",
    component: () => import("/builds/dpa-gmbh/produktionssysteme/rubix/rubix-monitor-frontend/pages/privacy-policy/index.vue")
  },
  {
    name: "settings",
    path: "/settings",
    meta: indexGWZTWO3PCKMeta || {},
    component: () => import("/builds/dpa-gmbh/produktionssysteme/rubix/rubix-monitor-frontend/pages/settings/index.vue")
  }
]